.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  background-color: 'black';
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 60px);  /* Adjust as needed */
  width: 100%;
  align-items: center;
  margin-top: -160px; /* Adjust as needed */
  /* background-color: #000; */
}

/* other styles remain the same */


.title {
  /* move to top of the page */
  font-size: 2.5vw;
  font-style: italic;
  font-weight: 100;
  padding-top: 100px;
  color: black;
}

.input-text {
  height: 25px;
  width: 80%;
  border: 1px solid #fff;
  color: #fff;
  padding: 10px;
  background-color: #000;
}

.button {
  width: 75px;
  height: 48px;
  display: flex;         /* Added this line */
  justify-content: center; /* Added this line */
  align-items: center;    /* Added this line */
  margin-top: 20px;
  background-color: #fff;
  color: #000;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9vw;  /* Adjust as needed */
  font-weight: bold; /* Makes the text bold */
}

.response-container {
  margin-top: 30px;
  width: 80%;
  text-align: center;
  color: #fff;
}
